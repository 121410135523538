<template>
	<r-form ref="giveIntroForm" :submitMethod="nextPage" class="give-intro-wrapper">
		<r-alert v-if="transactionState.alreadyPaidAmount > 0" type="info" dismissible>{{ transactionState.alreadyPaidAmount | dollars }} has already been paid for this invoice.</r-alert>
		<r-alert v-if="transactionState.lastGift.lastGiftDate" type="info" dismissible> A {{ isPaymentForm ? 'payment' : 'gift' }} of {{ transactionState.lastGift.amount | dollars }} was made {{ transactionState.lastGift.lastGiftDate | fromNow }} from this device. </r-alert>
		<r-alert v-if="givingFormState.editingRecurrenceIsComplete" type="info" dismissible>The recurrence you want to access has completed. Use this form to create a new one.</r-alert>
		<div v-if="fundState.onlineFunds.length == 0">
			<r-alert type="warning">Online Giving is unavailable. </r-alert>
			<v-spacer></v-spacer>
			<r-btn v-if="cancelAction" color="default" class="mr-2" @click="cancelAction">Cancel</r-btn>
		</div>
		<div v-else>
			<DonorTitle :font="givingFormState.givingForm.font" class="pb-1">{{ computedHeader }}</DonorTitle>
			<AmountFundSection v-for="(amountGroup, index) in transactionState.transaction.amountsList" ref="amountFundSectionRef" :key="index" :amountGroup="amountGroup" :amountGroupIndex="index"></AmountFundSection>
			<AddFundButton v-if="canSelectFunds" />
			<MemorialSelect v-if="isMemorialEnabled" />
			<Frequency v-show="!isOneTimePaymentOnly" />
			<r-text-input v-if="!givingFormState.transactionAccount.hasEmail" v-model="givingFormState.transactionAccount.primaryEmail" class="mt-1 mt-sm-3 mb-n1 mb-sm-n3" type="email" label="Email address" autocomplete="email" required outlined maxlength="255"></r-text-input>
			<r-alert v-for="pledgeMessage in pledgeMessages" :key="pledgeMessage.text" type="info">
				{{ pledgeMessage.text }}
			</r-alert>
			<AmountSummary v-if="showAmountSummary" class="mt-1 mt-sm-3" form-type="Giving" :recurrence-count="recurrenceCount" :recurrence-count-loading="fetchingRecurrenceCount" />
			<v-layout v-if="!isSinglePageForm">
				<v-spacer></v-spacer>
				<v-flex text-right>
					<r-btn v-if="cancelAction" color="default" class="mr-2" @click="cancelAction">Cancel</r-btn>
					<ActionButton type="submit" :color="givingFormState.givingForm.buttonColor" :font="givingFormState.givingForm.font">Continue</ActionButton>
				</v-flex>
			</v-layout>
		</div>
	</r-form>
</template>
<script>
import ActionButton from '@/components/giving/sharedForms/ActionButton.vue';
import AmountFundSection from '@/components/giving/donorForm/AmountFundSection.vue';
import Frequency from '@/components/giving/donorForm/Frequency.vue';
import AddFundButton from '@/components/giving/donorForm/AddFundButton.vue';
import DonorTitle from '@/components/giving/sharedForms/DonorTitle.vue';
import AmountSummary from '@/components/giving/sharedForms/AmountSummary';
import MemorialSelect from '@/components/giving/donorForm/MemorialSelect.vue';
import debounce from 'lodash/debounce';

export default {
	components: {
		AmountFundSection,
		Frequency,
		AddFundButton,
		ActionButton,
		DonorTitle,
		AmountSummary,
		MemorialSelect
	},
	data() {
		return {
			givingFormState: _REALM.STORE.givingFormStore.state,
			siteState: _REALM.STORE.siteStore.state,
			transactionState: _REALM.STORE.transactionStore.state,
			transactionStore: _REALM.STORE.transactionStore,
			accountState: _REALM.STORE.accountStore.state,
            fundState: _REALM.STORE.fundStore.state,
			accountStore: _REALM.STORE.accountStore,
			realmVm: _REALM.VM,
			pledgeMessages: [],
			debouncedPledgeCompletionMessage: null,
			debouncedRecurrenceCount: () => null,
			recurrenceCount: 1,
			fetchingRecurrenceCount: false
		};
	},
	computed: {
		cancelAction() {
			if (this.isGivingFormEmbed || !this.isGivingFormView) {
				return this.closeDonorForm;
			} else if (_REALM.VM.$route.query.recurrenceId) {
				const query = { ...this.$route.query };
				delete query.recurrenceId;
				return () => _REALM.VM.$router.push({ name: 'scheduled-gifts', query });
			}

			return null;
		},
		isGivingFormEmbed() {
			return _REALM.STORE.givingFormStore.isGivingFormEmbed();
		},
		isGivingFormView() {
			return _REALM.VM.$route.params.givingFormUrl !== undefined;
		},
		isPaymentForm() {
			return _REALM.STORE.layoutStore.isPaymentForm();
		},
		computedHeader() {
			if (this.isPaymentForm) {
				return 'Submit your payment';
			}

			return !_REALM.STORE.givingFormStore.isPreviewGivingFormMode() && _REALM.STORE.givingFormStore.isAdminManaging() ? 'Enter Online Gift for ' + this.givingFormState.transactionAccount.label : 'How much would you like to give?';
		},
		isSinglePageForm() {
			return _REALM.STORE.givingFormStore.isSinglePageForm();
		},
		isOneTimePaymentOnly() {
			return _REALM.STORE.givingFormStore.isOneTimePaymentOnly();
		},
		canSelectFunds() {
			return _REALM.STORE.givingFormStore.canSelectFunds();
		},
		showAmountSummary() {
			return !this.isSinglePageForm && !this.isOneTimePaymentOnly;
		},
		isMemorialEnabled() {
			return _REALM.STORE.givingFormStore.isMemorialEnabled();
		}
	},
	watch: {
		'transactionState.transaction': {
			handler() {
				this.pledgeMessages = [];
				if (typeof this.debouncedPledgeCompletionMessage === 'function') {
					this.debouncedPledgeCompletionMessage();
				}
			},
			deep: true
		},
		'transactionState.transaction.frequencyType'() {
			this.updateRecurrenceCount();
		},
		'transactionState.transaction.completionType'() {
			this.updateRecurrenceCount();
		},
		'transactionState.transaction.giftDate'(newVal, oldVal) {
			if (newVal && oldVal && newVal.toISOString() === oldVal.toISOString()) {
				return;
			}
			this.updateRecurrenceCount();
		},
		'transactionState.transaction.stopDate'(newVal, oldVal) {
			if (newVal && oldVal && newVal.toISOString() === oldVal.toISOString()) {
				return;
			}
			this.updateRecurrenceCount();
		},
		'transactionState.transaction.firstDayOfMonth'() {
			this.updateRecurrenceCount();
		},
		'transactionState.transaction.secondDayOfMonth'() {
			this.updateRecurrenceCount();
		},
		'transactionState.transaction.numberOfTimes'() {
			this.updateRecurrenceCount();
		},
		recurrenceCount(newValue) {
			this.transactionState.transaction.recurrenceCount = newValue;
		}
	},
	mounted() {
		if (this.givingFormState.hasOwnProperty('isSsoReturn') && this.givingFormState.isSsoReturn) {
			this.$nextTick(() => {
				// Use nextPage so that we revalidate form data
				this.nextPage();
				delete this.givingFormState.isSsoReturn;
			});
		}
	},
	created() {
		this.getEditRecurrence();
		this.debouncedPledgeCompletionMessage = debounce(this.pledgeCompletionMessage, 1000);
		this.debouncedRecurrenceCount = debounce(this.getRecurrenceCount, 500);
		this.updateRecurrenceCount();
	},
	methods: {
		pledgeCompletionMessage() {
			var self = this;
			if (_REALM.STORE.accountStore.isLoggedIn() && self.transactionState.transaction.amountsList[0].amount > 0) {
				_REALM.STORE.transactionStore.calculatePledgeMessage(self.transactionState.transaction, self.givingFormState.transactionAccount.individualId).then(function(messages) {
					self.pledgeMessages = messages;
				});
			}
		},
		getEditRecurrence() {
			var self = this;

			if (!_REALM.VM.$route.query.recurrenceId) {
				self.getLastGift();
				return;
			}

			_REALM.STORE.transactionStore
				.getRecurringGift(_REALM.VM.$route.query.recurrenceId)
				.then(_REALM.STORE.transactionStore.setEditRecurrence)
				.then(self.getLastGift);
		},
		closeDonorForm() {
			_REALM.EVENT.emit('close-donor-form');
		},
		redirectNextPage(pageName) {
			this.givingFormState.currentDonorPage = pageName;
		},
		nextPage() {
			var self = this;
			if (self.validateForm()) {
				if (_REALM.STORE.accountStore.isLoggedIn() || _REALM.STORE.givingFormStore.isTextGiver() || _REALM.TOOLS.inIframe()) {
					self.givingFormState.currentDonorPage = 'PaymentMethod';
				} else {
					_REALM.LOADER.show();
					_REALM.STORE.accountStore.emailHasAccount(self.givingFormState.transactionAccount.primaryEmail).then(function(emailHasAccount) {
						_REALM.LOADER.hide();
						self.transactionState.transaction.donorHasAccount = emailHasAccount;
						self.givingFormState.currentDonorPage = emailHasAccount ? 'KnownGiver' : 'PaymentMethod';
					});
				}
			}
		},
		updateRecurrenceCount() {
			if (this.transactionState.transaction.frequencyType === this.transactionStore.enums.mint_Recurrence_FrequencyType.ONCE) {
				this.recurrenceCount = 1;
				return;
			} else if (this.transactionState.transaction.completionType === this.transactionStore.enums.mint_Recurrence_CompletionType.STOP_DATE) {
				this.fetchingRecurrenceCount = true;
				this.debouncedRecurrenceCount();
				return;
			}

			this.recurrenceCount = this.transactionState.transaction.numberOfTimes;
		},
		getRecurrenceCount() {
			if (this.transactionState.transaction.completionType !== this.transactionStore.enums.mint_Recurrence_CompletionType.STOP_DATE || !this.transactionState.transaction.stopDate) {
				this.fetchingRecurrenceCount = false;
				return;
			}

			const args = {
				frequency: this.transactionState.transaction.frequencyType,
				startDate: this.transactionState.transaction.giftDate.format('MM/DD/YYYY'),
				stopDate: this.transactionState.transaction.stopDate.format('MM/DD/YYYY')
			};

			if (this.transactionState.transaction.frequencyType === this.transactionStore.enums.mint_Recurrence_FrequencyType.TWICE_A_MONTH) {
				args.day1 = this.transactionState.transaction.firstDayOfMonth;
				args.day2 = this.transactionState.transaction.secondDayOfMonth;
			}

			const self = this;
			this.transactionStore
				.getRecurrenceCount(args)
				.then(data => {
					self.recurrenceCount = data.count;
				})
				.finally(() => {
					self.fetchingRecurrenceCount = false;
				});
		},
		validateTotalAmount() {
			if (this.transactionStore.totalGiftAmount() < 300) {
				_REALM.TOAST.error('Total amount must be greater than $3.00');
				return false;
			}

			var invalidAmounts = this.transactionStore.state.transaction.amountsList.filter(function(a) {
				return a.amount < 0;
			});

			if (invalidAmounts.length > 0) {
				_REALM.TOAST.error('Negative gift amounts are not allowed');
				return false;
			}

			return true;
		},
		validateGiftDate() {
			return _REALM.STORE.transactionStore.validateTransactionStartDate();
		},
		getLastGift() {
			this.transactionStore.getLastGift();
		},
		validateForm() {
			return this.$refs.giveIntroForm.validate() && this.validateTotalAmount() && this.validateGiftDate() && _REALM.STORE.transactionStore.validateTransactionStopDate();
		}
	}
};
</script>
